import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import styles from "../../styles/Tabel.module.css";
import { useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { MdCancel } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { AddShipments, DeleteShipment, UpdateShipments, getRegions, getShipments } from "../../store/HomeSlice";
import { useDispatch, useSelector } from "react-redux";

export default function ShippingDash() {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { ShipmentsArr } = useSelector((state) => state.HomeSlice);
  const { RegionsArr } = useSelector((state) => state.HomeSlice);

  const [Savetype, setSavetype] = useState("Save");

  // *NOTE -  AddCountry
  const [Id, setId] = useState(null);
  const [currency, setCurrency] = useState("");
  const [region_id, setRegion_id] = useState("");
  const [shipping_fee, setShipping_fee] = useState("");
  const [free_shipping_min_amount, setFree_shipping_min_amount] = useState("");

  const Create = async () => {
      const data = {
          region_id,
          shipping_fee,
          currency,
          free_shipping_min_amount
      };
      if (Savetype === "Save") {
        if (!shipping_fee || region_id.length <= 0 || currency.length <= 0 || !free_shipping_min_amount) {
              showError("Please enter all data ");
          } else {
              dispatch(AddShipments(data))
                  .unwrap()
                  .then((res) => {
                      if (res.success) {
                          showSuccess();
                          Cleardialog();
                          dispatch(getShipments());
                      } else {
                          showError(res.errors[0]);
                      }
                  });
          }
      } else {
          const Data = {
              id: Id,
              data,
          };
          await dispatch(UpdateShipments(Data))
              .unwrap()
              .then((res) => {
                  if (res.success) {
                      showSuccess();
                      Cleardialog();
                      dispatch(getShipments());
                  } else {
                      res.errors.map((e) => showError(e));
                  }
              });
      }
  };

  useEffect(() => {
      if (!ShipmentsArr || !RegionsArr) {
          dispatch(getShipments());
          dispatch(getRegions());
      }
      console.log(RegionsArr);

  }, [ShipmentsArr,RegionsArr, dispatch]);

  const toast = useRef(null);

  const showSuccess = () => {
      toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Saved successfully",
          life: 3000,
      });
  };

  const showError = (e) => {
      toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${e}`,
          life: 3000,
      });
  };
  const toastBC = useRef(null);

  const clear = (submit) => {
      toastBC.current.clear();
      submit && showSuccess();
  };

  const Cleardialog = () => {
      setVisible(false);
      setShipping_fee("")
      setCurrency("")
      setFree_shipping_min_amount("")
      setId(null)
      setRegion_id("")
  };

  const confirm = (id) => {
      toastBC.current.show({
          severity: "info",
          sticky: true,
          className: "border-none",
          content: (
              <div className="flex flex-column align-items-center" style={{ flex: "1" }} >
                  <div className="text-center">
                      <i className="pi pi-exclamation-triangle" style={{ fontSize: "3rem" }}></i>
                      <div className="font-bold text-xl my-3">Are you sure?</div>
                  </div>
                  <div className="flex gap-2">
                      <Button onClick={(e) => {
                          dispatch(DeleteShipment(id))
                              .unwrap()
                              .then((res) => {
                                  if (res.message === 'Deleted Successfully') {
                                      clear(true);
                                  }
                              });
                      }}
                          type="button"
                          label="Confirm"
                          className="p-button-success w-6rem"
                      />
                      <Button
                          onClick={(e) => clear(false)}
                          type="button"
                          label="Cancel"
                          className="p-button-warning w-6rem"
                      />
                  </div>
              </div>
          ),
      });
  };

  const renderHeader2 = () => {
      return (
          <div className={`flex justify-content-between flex-wrap ${styles.tabel_header}`}>
              <button className={styles.addBTN}
                  onClick={() => {
                      setVisible(true);
                      setSavetype("Save");
                  }}
              >
                  <span className="icon-add"></span> Add Shipment
              </button>
          </div>
      );
  };
  const header2 = renderHeader2();

  const StatusBody = (rowData) => {
      return (
          <div className={`flex justify-content-center text-center`}>
              <button
                  className={`${styles.TabelButton} ${styles.Edite}`}
                  onClick={() => {
                      setSavetype("update");
                      setVisible(true);
                      setId(rowData.id);
                      setRegion_id(rowData.region_id);
                      setShipping_fee(rowData.shipping_fee);
                      setCurrency(rowData.currency);
                      setFree_shipping_min_amount(rowData.free_shipping_min_amount);
                  }}
              >
                  <AiFillEdit />
              </button>

              <button className={`${styles.TabelButton} ${styles.Cancel}`} onClick={() => confirm(rowData.id)} >
                  <MdCancel />
              </button>
          </div>
      );
  };

  return <>
      <Toast ref={toast} />
      <Toast ref={toastBC} position="bottom-center" />
      <div className={styles.Tabel}>
          <DataTable
              paginator
              selectionMode="single"
              value={ShipmentsArr}
              className={`${styles.dataTabel}`}
              dataKey="id"
              scrollable
              scrollHeight="100vh"
              filterDisplay="row"
              responsiveLayout="scroll"
              tableStyle={{ minWidth: "50rem" }}
              header={header2}
              rows={10}
          >
              <Column field="id" header=" ID " style={{ maxWidth: "7rem" }} />
              <Column field="region_id" header=" region_id " style={{ maxWidth: "7rem" }} />
              <Column field="shipping_fee" header=" shipping_fee " style={{ maxWidth: "7rem" }} />
              <Column field="free_shipping_min_amount" header=" free_shipping_min_amount " style={{ maxWidth: "7rem" }} />
              <Column field="currency" header=" Currency " style={{ maxWidth: "7rem" }} />
              <Column header=" Status " body={StatusBody} style={{ maxWidth: "7rem" }} />

          </DataTable>

          {/* *NOTE - Create / Edit Dialog */}
          <Dialog
              visible={visible}
              style={{ maxWidth: "90vw" }}
              onHide={() => Cleardialog()}
          >
              <h1 className="main-two">
                  {Savetype === "Save" ? "Genertare Country" : "Update Country"}
              </h1>

              <div className="main-container ">
                  <div className="  card grid justify-content-center  align-items-center    ">
                      <div className="col-12 md:col-6">
                          {/* <span className="p-float-label">
                              <InputText
                                  className={`${region_id.length <= 0 ? "p-invalid" : ""}`}
                                  id="region_id"
                                  value={region_id}
                                  onChange={(e) => setRegion_id(e.target.value)}
                              />
                              <label htmlFor="region_id">region_id</label>
                          </span> */}
                                <label htmlFor="countrySelect" className={`form-label ${styles.me-2}`}>
        Select Country:
      </label>
      <select
        id="countrySelect"
        className= {`${styles.formSelect}`}
        onChange={(e) => setRegion_id(e)}
        required
      >
        <option value="" disabled>Choose...</option>
        {RegionsArr.map((country) => (
          <option key={country.id} value={country.id}>
            {country.name}
          </option>
        ))}
      </select>

                      </div>
                      <div className="col-12 md:col-6">
                          <span className="p-float-label">
                              <InputText
                                  className={`${currency.length <= 0 ? "p-invalid" : ""}`}
                                  id="currency"
                                  value={currency}
                                  onChange={(e) => setCurrency(e.target.value)}
                              />
                              <label htmlFor="Currency">Currency</label>
                          </span>
                      </div>

                      <div className="col-12 md:col-6">
                          <span className="p-float-label">
                              <InputText
                                  className={`${free_shipping_min_amount.length <= 0 ? "p-invalid" : ""}`}
                                  id="free_shipping_min_amount"
                                  value={free_shipping_min_amount}
                                  onChange={(e) => setFree_shipping_min_amount(e.target.value)}
                              />
                              <label htmlFor="free_shipping_min_amount">free_shipping_min_amount</label>
                          </span>
                      </div>

                      <div className="col-12 md:col-6">
                          <span className="p-float-label">
                              <InputText
                                  className={`${shipping_fee?.length <= 0 ? "p-invalid" : ""}`}
                                  id="shipping_fee"
                                  value={shipping_fee}
                                  onChange={(e) => setShipping_fee(e.target.value)}
                              />
                              <label htmlFor="shipping_fee">shipping_fee</label>
                          </span>
                      </div>
                      <div
                          className="flex  mt-5 align-items-center justify-content-center switch col-12 md:col-6  "
                      >
                          <button className="main_Btn" onClick={() => Create()}>
                              Save
                          </button>
                      </div>
                  </div>
              </div>
          </Dialog>

      </div>
  </>

}

