import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import styles from "../../styles/Tabel.module.css";
import { useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { MdCancel } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Dialog } from "primereact/dialog";
import { getCountries, DeleteCountry, AddCountries, UpdateCountries } from "../../store/HomeSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";

export default function CountriesDash() {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const { CountriesArr } = useSelector((state) => state.HomeSlice);

    const [Savetype, setSavetype] = useState("Save");

    // *NOTE -  AddCountry
    const [Id, setId] = useState(null);
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [currency, setCurrency] = useState("");
    const Create = async () => {
        const data = {
            name,
            code,
            currency,
        };
        if (Savetype === "Save") {
            if (!code || name.length <= 0 || currency.length <= 0) {
                showError("Please enter all data ");
            } else {
                dispatch(AddCountries(data))
                    .unwrap()
                    .then((res) => {
                        if (res.success) {
                            showSuccess();
                            Cleardialog();
                            dispatch(getCountries());
                        } else {
                            showError(res.errors[0]);
                        }
                    });
            }
        } else {
            const Data = {
                id: Id,
                data,
            };
            await dispatch(UpdateCountries(Data))
                .unwrap()
                .then((res) => {
                    if (res.success) {
                        showSuccess();
                        Cleardialog();
                        dispatch(getCountries());
                    } else {
                        res.errors.map((e) => showError(e));
                    }
                });
        }
    };

    useEffect(() => {
        if (!CountriesArr) {
            dispatch(getCountries());
        }
    }, [CountriesArr, dispatch]);

    const toast = useRef(null);
    const toastBC = useRef(null);

    const showSuccess = () => {
        toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Saved successfully",
            life: 3000,
        });
    };

    const showError = (e) => {
        toast.current.show({
            severity: "error",
            summary: "Error",
            detail: `${e}`,
            life: 3000,
        });
    };

    const clear = (submit) => {
        toastBC.current.clear();
        submit && showSuccess();
    };

    const Cleardialog = () => {
        setVisible(false);
        setCode("")
        setCurrency("")
        setId(null)
        setName("")
      };

    const confirm = (id) => {
        toastBC.current.show({
            severity: "info",
            sticky: true,
            className: "border-none",
            content: (
                <div className="flex flex-column align-items-center" style={{ flex: "1" }} >
                    <div className="text-center">
                        <i className="pi pi-exclamation-triangle" style={{ fontSize: "3rem" }}></i>
                        <div className="font-bold text-xl my-3">Are you sure?</div>
                    </div>
                    <div className="flex gap-2">
                        <Button onClick={(e) => {
                            dispatch(DeleteCountry(id))
                                .unwrap()
                                .then((res) => {
                                    if (res.message === 'Deleted Successfully') {
                                        clear(true);
                                    }
                                });
                        }}
                            type="button"
                            label="Confirm"
                            className="p-button-success w-6rem"
                        />
                        <Button
                            onClick={(e) => clear(false)}
                            type="button"
                            label="Cancel"
                            className="p-button-warning w-6rem"
                        />
                    </div>
                </div>
            ),
        });
    };

    const renderHeader2 = () => {
        return (
            <div className={`flex justify-content-between flex-wrap ${styles.tabel_header}`}>
                <button className={styles.addBTN}
                    onClick={() => {
                        setVisible(true);
                        setSavetype("Save");
                    }}
                >
                    <span className="icon-add"></span> Add Country
                </button>
            </div>
        );
    };
    const header2 = renderHeader2();

    const StatusBody = (rowData) => {
        return (
            <div className={`flex justify-content-center text-center`}>
                <button
                    className={`${styles.TabelButton} ${styles.Edite}`}
                    onClick={() => {
                        setSavetype("update");
                        setVisible(true);
                        setId(rowData.id);
                        setName(rowData.name);
                        setCode(rowData.code);
                        setCurrency(rowData.currency);
                    }}
                >
                    <AiFillEdit />
                </button>

                <button className={`${styles.TabelButton} ${styles.Cancel}`} onClick={() => confirm(rowData.id)} >
                    <MdCancel />
                </button>
            </div>
        );
    };

    return <>
        <Toast ref={toast} />
        <Toast ref={toastBC} position="bottom-center" />
        <div className={styles.Tabel}>
            <DataTable
                paginator
                selectionMode="single"
                value={CountriesArr}
                className={`${styles.dataTabel}`}
                dataKey="id"
                scrollable
                scrollHeight="100vh"
                filterDisplay="row"
                responsiveLayout="scroll"
                tableStyle={{ minWidth: "50rem" }}
                header={header2}
                rows={10}
            >
                <Column field="id" header=" ID " style={{ maxWidth: "7rem" }} />
                <Column field="name" header=" Name " style={{ maxWidth: "7rem" }} />
                <Column field="code" header=" Code " style={{ maxWidth: "7rem" }} />
                <Column field="currency" header=" Currency " style={{ maxWidth: "7rem" }} />
                <Column header=" Status " body={StatusBody} style={{ maxWidth: "7rem" }} />

            </DataTable>

            {/* *NOTE - Create / Edit Dialog */}
            <Dialog
                visible={visible}
                style={{ maxWidth: "90vw" }}
                onHide={() => Cleardialog()}
            >
                <h1 className="main-two">
                    {Savetype === "Save" ? "Genertare Country" : "Update Country"}
                </h1>

                <div className="main-container ">
                    <div className="  card grid justify-content-center  align-items-center    ">
                        <div className="col-12">
                            <span className="p-float-label">
                                <InputText
                                    className={`${name.length <= 0 ? "p-invalid" : ""}`}
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <label htmlFor="name">Name</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText
                                    className={`${currency.length <= 0 ? "p-invalid" : ""}`}
                                    id="currency"
                                    value={currency}
                                    onChange={(e) => setCurrency(e.target.value)}
                                />
                                <label htmlFor="Currency">Currency</label>
                            </span>
                        </div>

                        <div className="col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText
                                    className={`${code.length <= 0 ? "p-invalid" : ""}`}
                                    id="Code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                                <label htmlFor="Code">Code </label>
                            </span>
                        </div>
                        <div
                            className="flex  mt-5 align-items-center justify-content-center switch col-12 md:col-6  "
                        >
                            <button className="main_Btn" onClick={() => Create()}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>

        </div>
    </>

}

