import ShippingDash from "../components/DashBoard/ShippingDash";

const Shipping = () => {
  return (
    <div className="container-fluid">
      <h1 className="main-title">Shipping</h1>
      <div className="mb-5 flex">
        <span className="icon-home"></span>{" "}
        <p className="ml-1"> DashBoard / Shipping</p>
      </div>
      <ShippingDash />
    </div>
  );
};

export default Shipping;
