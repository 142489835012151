import RegionsDash from "../components/DashBoard/RegionsDash";

const Regions = () => {
    return (
      <div className="container-fluid">
        <h1 className="main-title">Regions</h1>
        <div className="mb-5 flex">
          <span className="icon-home"></span>{" "}
          <p className="ml-1"> DashBoard / Regions</p>
        </div>
        <RegionsDash/>
      </div>
    );
  };
  
  export default Regions;
  