import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import styles from "../../styles/Tabel.module.css";
import { useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { MdCancel } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { AddRegions, DeleteRegion, UpdateRegions, getCountries, getRegions } from "../../store/HomeSlice";

export default function RegionsDash() {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const { RegionsArr } = useSelector((state) => state.HomeSlice);
    const { CountriesArr } = useSelector((state) => state.HomeSlice);

    const [Savetype, setSavetype] = useState("Save");

    // *NOTE -  AddCountry
    const [Id, setId] = useState(null);
    const [name, setName] = useState("");
    const [country_ids, setCountry_ids] = useState([]);
    const Create = async () => {
        const data = {
            name,
            country_ids,
        };
        if (Savetype === "Save") {
            if (!country_ids || name.length <= 0) {
                showError("Please enter all data ");
            } else {
                dispatch(AddRegions(data))
                    .unwrap()
                    .then((res) => {
                        if (res.success) {
                            showSuccess();
                            Cleardialog();
                            dispatch(getRegions());
                        } else {
                            showError(res.errors[0]);
                        }
                    });
            }
        } else {
            const Data = {
                id: Id,
                data,
            };
            await dispatch(UpdateRegions(Data))
                .unwrap()
                .then((res) => {
                    if (res.success) {
                        showSuccess();
                        Cleardialog();
                        dispatch(getRegions());
                    } else {
                        res.errors.map((e) => showError(e));
                    }
                });
        }
    };

    useEffect(() => {
        if (!RegionsArr || !CountriesArr) {
            dispatch(getRegions());
            dispatch(getCountries());
        }
    }, [RegionsArr, CountriesArr, dispatch]);

    const toast = useRef(null);
    const toastBC = useRef(null);

    const showSuccess = () => {
        toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Saved successfully",
            life: 3000,
        });
    };

    const showError = (e) => {
        toast.current.show({
            severity: "error",
            summary: "Error",
            detail: `${e}`,
            life: 3000,
        });
    };

    const clear = (submit) => {
        toastBC.current.clear();
        submit && showSuccess();
    };

    const Cleardialog = () => {
        setVisible(false);
        setId(null)
        setName('')
        setCountry_ids([])
    };

    const confirm = (id) => {
        toastBC.current.show({
            severity: "info",
            sticky: true,
            className: "border-none",
            content: (
                <div className="flex flex-column align-items-center" style={{ flex: "1" }} >
                    <div className="text-center">
                        <i className="pi pi-exclamation-triangle" style={{ fontSize: "3rem" }}></i>
                        <div className="font-bold text-xl my-3">Are you sure?</div>
                    </div>
                    <div className="flex gap-2">
                        <Button onClick={(e) => {
                            dispatch(DeleteRegion(id))
                                .unwrap()
                                .then((res) => {
                                    if (res.message === 'Deleted Successfully') {
                                        clear(true);
                                    }
                                });
                        }}
                            type="button"
                            label="Confirm"
                            className="p-button-success w-6rem"
                        />
                        <Button
                            onClick={(e) => clear(false)}
                            type="button"
                            label="Cancel"
                            className="p-button-warning w-6rem"
                        />
                    </div>
                </div>
            ),
        });
    };

    const renderHeader2 = () => {
        return (
            <div className={`flex justify-content-between flex-wrap ${styles.tabel_header}`}>
                <button className={styles.addBTN}
                    onClick={() => {
                        setVisible(true);
                        setSavetype("Save");
                        setCountry_ids([]);
                    }}
                >
                    <span className="icon-add"></span> Add Region
                </button>
            </div>
        );
    };
    const header2 = renderHeader2();

    const StatusBody = (rowData) => {
        return (
            <div className={`flex justify-content-center text-center`}>
                <button
                    className={`${styles.TabelButton} ${styles.Edite}`}
                    onClick={() => {
                        setSavetype("update");
                        setVisible(true);
                        setId(rowData.id);
                        setName(rowData.name);
                        // *FIXME - creat a new state for selected region's countries
                        setCountry_ids([]);
                    }}
                >
                    <AiFillEdit />
                </button>

                <button className={`${styles.TabelButton} ${styles.Cancel}`} 
                    onClick={() => {
                        confirm(rowData.id)
                        }} >
                    <MdCancel />
                </button>
            </div>
        );
    };

    const renderCountries = (rowData) => {
        return rowData.countries.map(country => country.name).join(", ");
    };
    return <>
        <Toast ref={toast} />
        <Toast ref={toastBC} position="bottom-center" />
        <div className={styles.Tabel}>
            <DataTable
                paginator
                selectionMode="single"
                value={RegionsArr}
                className={`${styles.dataTabel}`}
                dataKey="id"
                scrollable
                scrollHeight="100vh"
                filterDisplay="row"
                responsiveLayout="scroll"
                tableStyle={{ minWidth: "50rem" }}
                header={header2}
                rows={10}
            >
                <Column field="id" header=" ID " style={{ maxWidth: "7rem" }} />
                <Column field="name" header=" Name " style={{ maxWidth: "7rem" }} />
                <Column body={renderCountries} header=" Countries " style={{ maxWidth: "7rem" }} />
                <Column header=" Status " body={StatusBody} style={{ maxWidth: "7rem" }} />

            </DataTable>
            {/* *NOTE - Create / Edit Dialog */}
            <Dialog visible={visible} style={{ maxWidth: "90vw" }} onHide={() => Cleardialog()} >
                <h1 className="main-two">{Savetype === "Save" ? "Generate Region" : "Update Region"}</h1>
                <div className="main-container ">
                    <div className="  card grid justify-content-center align-items-center">
                        <div className="col-12">
                            <span className="p-float-label">
                                <InputText
                                    className={`${name.length <= 0 ? "p-invalid" : ""}`}
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <label htmlFor="name">Name</label>
                            </span>
                        </div>
                        <div className="col-12">
                        {CountriesArr?.map((country) => (
                                    <span key={country.id} className={`${styles.me2}`}>
                                        <input
                                            type="checkbox"
                                            name={country.name}
                                            id={country.id}
                                            value={country.id}
                                            className={`${styles.me1}`}
                                            // checked={country_ids.includes(country.id)}
                                            onChange={(e) => {
                                                const isChecked = e.target.checked;
                                                const countryId = e.target.value;
                                                if (isChecked) {
                                                    setCountry_ids((prevIds) => [...prevIds, countryId]);
                                                } else {
                                                    setCountry_ids((prevIds) => prevIds.filter((id) => id !== countryId));
                                                }
                                            }}
                                        />
                                        <label htmlFor={country.id}>{country.name}</label>
                                    </span>
                                ))}
                            </div>
                        <div className="flex  mt-5 align-items-center justify-content-center switch col-12 md:col-6">
                            <button className="main_Btn" onClick={() => Create()}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    </>

}

